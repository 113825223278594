<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('edit')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-form id="editor" ref="editor" v-else>
            <v-row>
                <v-col cols="12" sm="6" md="6" lg="4">
                    <DashCard title="Propiedades" title-icon="edit">
                        <v-row dense>
                            <v-col cols="12">
                                <v-label>Fecha de Incidente</v-label>
                                <DatePickerText flat dense v-model="incidente.fecha_incidente" :rules="[rules.required]" />
                            </v-col>
                            <v-col cols="12">
                                <v-label>Código ID</v-label>
                                <v-text-field solo outlined flat dense hide-details type="number" v-model.number="incidente.cod_incidente" label="Código ID"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-label>Estado</v-label>
                                <v-select solo flat outlined dense hide-details :rules="[rules.required]" v-model="incidente.estado" :items="estados" item-text="text" item-value="value" prepend-inner-icon="flag" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="8">
                    <DashCard title="Descripción general" title-icon="assignment">
                        <v-row dense>
                            <v-col cols="12" sm="8">
                                <v-label>Titulo</v-label>
                                <v-text-field solo flat outlined dense hide-details :rules="[rules.required]" v-model="incidente.titulo" label="Titulo" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-label>Clasif.</v-label>
                                <v-select solo flat outlined dense hide-details :rules="[rules.required]" v-model="incidente.clasificacion" :items="clases" />
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-label>Observación</v-label>
                                <v-textarea solo flat outlined dense hide-details v-model="incidente.observacion" placeholder="Breve descripción o nueva información al respecto (Opcional)" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <DashCard title="Ubicación" title-icon="place">
                        <v-row dense>
                            <v-col cols="12">
                                <v-label>Area SIGRO</v-label>
                                <v-radio-group row hide-details class="ma-0" :rules="[rules.required]" v-model="incidente.area_sigro">
                                    <v-radio label="On Site" :value="1"></v-radio>
                                    <v-radio label="Off Site" :value="2"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12">
                                <v-label>Area</v-label>
                                <Selector dense 
                                        :rules="[rules.required]"
                                        v-model="incidente.area" 
                                        :items="areas" 
                                        return-object
                                        label="Área"
                                        create-dialog-title="Crear nueva Área" 
                                        create-dialog-placeholder="Nombre del Área"
                                        item-text="nombre" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-label>Gerencia</v-label>
                                <Selector dense
                                        :rules="[rules.required]" 
                                        v-model="incidente.gerencia" 
                                        :items="gerencias" 
                                        return-object
                                        label="Gerencia"
                                        create-dialog-title="Crear nueva gerencia" 
                                        create-dialog-placeholder="Nombre de la gerencia"
                                        item-text="nombre" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-label>Empresa</v-label>
                                <Selector dense 
                                        :rules="[rules.required]"
                                        v-model="incidente.empresa" 
                                        :items="empresas" 
                                        return-object
                                        label="Empresa"
                                        create-dialog-title="Crear nueva empresa" 
                                        create-dialog-placeholder="Nombre de la empresa"
                                        item-text="nombre" />
                            </v-col>
                        </v-row>
                    </DashCard>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <DashCard title="Responsable/Encargado" title-icon="person_pin" body-class="pa-0 px-4">
                        <EditorResponsable v-model="incidente" :personas="personas" :rules="[rules.required]" />
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <BoxGeoreferencia ref="geo" v-model="incidente.georeferencia" />
                </v-col>
                <v-col cols="12">
                    <DashCard title="Tareas" body-class="pa-0" :actions="true" actions-align="right">
                        <v-data-table :headers="tarea_header" :items="incidente.tareas" :mobile-breakpoint="0" :items-per-page="-1" hide-default-footer>
                            <template v-slot:item.vencimiento="{ item }">{{ item.vencimiento | moment("L") }}</template>
                            <template v-slot:item.cierre="{ item }">{{ item.cierre | moment("L") }}</template>
                            <template v-slot:item.estado="{ item }">
                                <v-chip dark :color="item.estado | color_estado('tarea')">
                                    {{ item.estado | estado('tarea') }}
                                </v-chip>
                            </template> 
                            <template v-slot:item.acciones="{ item }">
                                <v-btn v-if="!editMode" text fab small color="primary" depressed @click="eliminar_tarea(item)">
                                    <v-icon>close</v-icon>
                                </v-btn>
                                <v-btn text fab small color="primary" depressed @click="$refs.editor_tarea.show(true, item, incidente.tareas.indexOf(item))">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <template slot="actions">
                            <v-btn large depressed color="secondary" @click="$refs.editor_tarea.show(false)" form="editor"> 
                                <span class="subtitle-2">Añadir</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
                <v-col cols="12">
                    <DashCard :actions="true" actions-align="right">
                        <template slot="actions">
                            <v-btn type="submit" large depressed color="primary" @click="askSubir" form="editor">
                                <span class="subtitle-2">Guardar cambios</span>
                            </v-btn>
                            <v-btn large text color="primary" @click="askCancelar">
                                <span class="subtitle-2">Cancelar</span>
                            </v-btn>
                        </template>
                    </DashCard>
                </v-col>
            </v-row>
        </v-form>
        <DialogTarea ref="editor_tarea" @success="add_tarea" />
        <Message ref="success_dialog" @onClose="onUploadSuccessClose" />
        <Message ref="error_dialog" />
        <CommonDialog ref="cancel_dialog" @onClose="onCancelClose" />
    </div>
</template>


<script>
import DialogTarea from '@/dash/dialogs/DialogTarea'
import CommonDialog from '@/shared/components/CommonDialog'
import Selector from '@/dash/components/Selector'
import CustomSelector from '@/dash/components/CustomSelector'
import DashCard from '@/shared/components/DashCard'
import MapCard from '@/dash/components/MapCard'
import MapMarkerCard from '@/dash/components/MapMarkerCard'
import DatePickerText from '@/shared/components/DatePickerText'
import PersonaListItem from '@/dash/components/PersonaListItem'
import EditorResponsable from '@/dash/components/EditorResponsable'
import Message from '@/shared/components/Message'

import BoxGeoreferencia from '@/dash/form_boxes/box_georeferencia'
import AccessMessage from '@/dash/components/AccessMessage'

import moment from 'moment'
import raw_estados from '@/assets/estados'
import { axiosHelper, messages, json_clases_incidente, valid_required } from '@/helpers'

export default {
    components: {
        Message,
        DialogTarea,
        DashCard,
        CustomSelector,
        Selector,
        DatePickerText,
        CommonDialog,
        MapCard,
        MapMarkerCard,
        PersonaListItem,
        EditorResponsable,
        BoxGeoreferencia,
        AccessMessage
    },
    computed: {
        editMode() {
            return this.$route.meta.editMode;
        },
        areas() {
            return this.editor_data.areas || [];
        },
        gerencias() {
            return this.editor_data.gerencias || [];
        },
        empresas() {
            return this.editor_data.empresas || [];
        },
        personas() {
            return this.editor_data.personas || [];
        },
        estados() {
            return raw_estados['incidente'];
        },
        clases() {
            return json_clases_incidente();
        }
    },
    data: () => ({
        rules: {
            required: value => valid_required(value)
        },
        editor_data: {},
        incidente: {
            fecha_incidente: moment().format("YYYY-MM-DD"),
            is_georeferenciado: false,
            georeferencia: null,
            tareas: []
        },
        tarea_header: 
        [
            { text: "Tarea", value: "texto", sortable: false },
            { text: "Estado", value: "estado", sortable: false },
            { text: "Vencimiento", value: "vencimiento", sortable: false },
            { text: "Cierre", value: "cierre", sortable: false },
            { text: "Acciones", value: "acciones", sortable: false, align: 'center' }
        ]
    }),
    mounted() {
        if(!this.$parent.$parent.get_permission('edit')) return;

        let text = "";

        if(this.editMode) 
        {
            text = "Editar Registro de Incidente Ambiental";
            axiosHelper.get('incidentes/' + this.$route.params.id + '/editar')
                .then(response => {
                    this.editor_data = response.data;
                    this.incidente = this.editor_data.incidente;

                    if(this.incidente.tareas.length < 1) 
                    {
                        this.add_default_tarea();
                    }
                })
        } else {
            text = "Registrar Incidente Ambiental";
            axiosHelper.get('incidentes/crear')
                .then(response => {
                    this.editor_data = response.data;
                    this.initialize();
                })
        }

        this.$emit("title", { text: text, icon: "edit" });
    },
    methods: {
        initialize() {
            if(!this.incidente.estado) {
                this.incidente.estado = 2;
            }

            if(this.incidente.tareas.length < 1) 
                this.add_default_tarea();

        },
        add_default_tarea() {
            this.add_tarea(
                { 
                    texto: 'Tratamiento de Incidente Ambiental', 
                    estado: 1,
                    vencimiento: moment(this.fecha_incidente).add('days', 10).format('YYYY-MM-DD')
                }
            , false);
        },
        add_tarea(item, edit, index) {
            if(!edit) {
                this.incidente.tareas.push(item);
            } else {
                this.$set(this.incidente.tareas, index, item);
                this.$forceUpdate();
            }
        },
        eliminar_tarea(item) {
            if(this.incidente.tareas.length < 2) 
            {
                this.$refs.error_dialog.show(messages.titles["error_generico"], messages.texts["error_incidente_tarea_one"], "errror")
                return;
            }

            this.incidente.tareas.splice(this.incidente.tareas.indexOf(item), 1);
        },
        askSubir(e) {
            e.preventDefault();

            if(!this.$refs.editor.validate()) {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_check_fields"], "error")
                return;
            }

            this.incidente.georeferencia = this.$refs.geo.get_data();

            if(this.incidente.tareas.length < 1) {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_incidente_tarea_one"], "error")
                return;
            }

            let flag_incidente_cierre = this.incidente.tareas
                                            .some(x => x.vencimiento < moment(this.incidente.fecha_incidente));

            if(flag_incidente_cierre) {
                this.$refs.error_dialog.show(messages.titles["error_upload_editor"], messages.texts["error_incidente_duracion"], "error")
                return;
            }

            if(this.editMode) {
                axiosHelper.put('incidentes/' + this.$route.params.id + '/', this.incidente)
                .then(response => this.success())
                .catch(error => this.error());
            } else {
                axiosHelper.post('incidentes/', this.incidente)
                .then(response => this.success())
                .catch(error => this.error());
            }
        },
        askCancelar(e) {
            e.preventDefault();
            this.$refs.cancel_dialog.showDialog(
                messages.titles[this.editMode ? "question_cancel_edit" : "question_cancel_new"], 
                messages.texts[this.editMode ? "question_cancel_edit" : "question_cancel_new"], "yes_no");
                
        },
        onCancelClose(e) {
            if(e == "yes") {
                this.$router.back();
            }
        },
        onUploadSuccessClose(e) {
            this.$router.back();
        },
        success() {
            this.$refs.success_dialog.show(
                messages.titles["success_generico"],
                messages.texts[this.editMode ? "success_upload_edit" : "success_upload_new"], "success");
        },
        error() {
            this.$refs.error_dialog.show(
                messages.titles["error_generico"], messages.texts["error_upload"], "error");
        }
    }
}
</script>