<template>
     <v-row dense>
        <v-dialog v-model="dialog.visible" :max-width="(dialogWidth || 400)" persistent transition="fade-transition">
            <WindowCard :title="dialogTitle" actions-align="right">
                <slot name="dialog-content" v-bind:item="item"></slot>
                <template slot="actions">
                    <v-btn text large color="primary" @click="closeDialog(false)">
                        <span class="subtitle-2">Descartar</span>
                    </v-btn>
                    <v-btn depressed large color="primary" @click="closeDialog(true)">
                        <span class="subtitle-2">Crear</span>
                    </v-btn>
                </template>
            </WindowCard>
        </v-dialog>
        <v-col cols="10">
            <v-select 
                hide-details 
                :rules="rules"
                :dense="dense"
                :return-object="returnObject"
                :items="items" 
                :item-text="itemText" 
                :item-value="itemValue"
                :label="label" 
                :value="value"
                v-on:input="handleValue"
                solo 
                outlined></v-select>
        </v-col>
        <v-col cols="2">
            <v-btn icon large color="primary" @click="showDialog">
                <v-icon large>add</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'

export default {
    components: {
        WindowCard
    },
    data: () => ({
        dialog: {
            visible: false
        },
        item: []
    }),
    props: ['value', 'dense', 'items', 'rules', 'item-text', 'item-value', 'return-object', 'label', 'dialog-title', 'dialog-width'],
    methods: {
        handleValue(item) {
            this.$emit('input', item);
        },
        showDialog() {
            this.dialog.visible = true;
        },
        closeDialog(response) {
            this.dialog.visible = false;

            if(response) {
                this.set(this.item);
            }
        },
        set(item) {
            if(!item) return;

            let newitem = { ...item };

            this.items.push(newitem);
            this.handleValue(newitem);
            this.$emit('returnItem', newitem);
            this.item = {};
        },
    }
}
</script>